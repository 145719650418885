import * as React from "react";
import { useMediaQuery } from "react-responsive";
import { StaticImage } from "gatsby-plugin-image";
import {
  container,
  homePage,
  menuItem,
  images,
  flexParent,
  flexChild,
} from "../layout.module.css";
import "../sidebar.css";
import Header from "./header";
import Sidebar from "./sidebar";
import Countdown from "react-countdown";
import { navigate } from "gatsby";
import { Button } from "@mui/material";
import Realistic from "react-canvas-confetti/dist/presets/realistic";
import confetti from "canvas-confetti";

// markup
const HomePage = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1000px)" });
  const decorateOptions = (defaultOptions) => {
    return {
      ...defaultOptions,
      origin: { x: 0.5, y: 0.5 },
      shapes: [confetti.shapeFromText({ text: "🧿" })],
      scalar: isTabletOrMobile ? 1 : 3,
      zIndex: 1000,
      particleCount: 10,
    };
  };

  return (
    <div className={container} style={{ position: "relative" }}>
      {isTabletOrMobile && MobileContent()}
      <Header></Header>
      <Realistic
        style={{
          position: "absolute",
          zIndex: "1000",
          width: "95%",
          height: "100%",
        }}
        autorun={{ speed: 0.3, duration: 15 }}
        decorateOptions={decorateOptions}
      />
      <StaticImage
        alt="vineet"
        src="../images/home-photo-2.png"
        style={{
          display: "block",
          height: "115%",
          position: "relative",
          zIndex: 2,
        }}
        className={images}
      />
      <p className={{ flexParent }}>
        <p
          className={{ flexChild }}
          style={{
            fontSize: `42px`,
            justifyContent: `center`,
            textAlign: `center`,
            alignItems: `center`,
            width: `50%`,
            borderRight: `solid 1px black`,
          }}
        >
          Athens Riviera, Greece
        </p>
        <p
          className={{ flexChild }}
          style={{
            fontSize: `42px`,
            justifyContent: `center`,
            alignItems: `center`,
            width: `50%`,
          }}
        >
          05.26.2025
        </p>
      </p>

      <Countdown
        date={new Date("May 25, 2025 00:00:00")}
        intervalDelay={0}
        renderer={(props) => (
          <div
            style={{
              fontSize: `26px`,
              justifyContent: "center",
              textAlign: `center`,
              alignItems: `center`,
            }}
          >
            {props.days} d {props.hours} h {props.minutes} min {props.seconds} s
          </div>
        )}
      />
      <br />
      <Button
        style={{
          fontSize: `20px`,
          borderRadius: "4px",
          fontWeight: `bold`,
          display: "flex",
          zIndex: "1001",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: "auto",
          marginRight: "auto",
          width: `300px`,
          textTransform: "none",
          fontFamily: "Cormorant Garamond",
          backgroundColor: "#034488",
        }}
        onClick={() => {
          navigate("https://www.zola.com/wedding/rsvp_vp/rsvp");
        }}
        variant="contained"
      >
        RSVP
      </Button>
      <StaticImage
        alt="vineet"
        src="../images/logo.png"
        style={{ display: "block", maxWidth: "50%", maxHeight: "50%" }}
        className={images}
      />
    </div>
  );
};

const MobileContent = () => {
  return (
    <div className={menuItem} id="outer-container">
      <Sidebar pageWrapId={"page-wrap"} outerContainerId={"outer-container"} />
      <p className={homePage}>VINEET NADELLA</p>
      <p style={{ fontSize: `20px`, justifyContent: "center" }}>&</p>
      <p className={homePage}>PRIYANKA KOLLI</p>
    </div>
  );
};

export default HomePage;
